import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";
import DmBrand from "@/vue/domain/brand/dm-brand";

@Component
export default class DfPdfViewerComponent extends Vue {
  get signboardsAuthorized(): Array<string> {
    const signboardsAuthorized: Array<any> = Utils.getPropertyValues(this.$store.getters.magazineContents[0], "INSEGNE", "LIST");
    return signboardsAuthorized.map((signboardAuthorized: any) => signboardAuthorized.key);
  }

  get pdfUrl(): string {
    const magazineData: any = this.signboardsAuthorized.includes(this.$route.params.signboardAlias) ? Utils.getPropertyValue(this.$store.getters.magazineContents[0], Utils.PROPERTY_PDF, "FILE") : null;
    const linkTargetProperty: any = Utils.getPropertyValue(this.$store.getters.magazineContents[0], Utils.PROPERTY_PDF_LINK_TARGET, "LIST");
    const linkTarget: string = linkTargetProperty ? linkTargetProperty.key : 0;
    const pdfJsOptions: string = this.mobileView ? `#zoom=page-fit&spreadmode=0&scrollmode=0&toolbarposition=bottom&externallinktarget=${linkTarget}` : `#zoom=page-fit&spreadmode=${this.spreadmode}&scrollmode=3&browsemode=1&toolbarposition=bottom&externallinktarget=${linkTarget}`;
    const url: string = magazineData ? `${configUtilities.getAppContextPath(true)}/files/${magazineData.uniqueId}/${magazineData.basename}.${magazineData.extension}` : null;
    return `${configUtilities.getResourcesPath()}/vendor/pdfjs-2.13.216x/web/viewer.html?nc=123&file=${url}${pdfJsOptions}`;
  }

  get mobileView(): boolean {
    return this.clientWidth <= 544;
  }

  get clientWidth(): number {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  get spreadmode(): number {
    switch (this.$route.params.signboardAlias) {
      case "emi":
        return 2;
      default:
        return 1;
    }
  }
}
